const Telegram = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 21.83">
      <path
        d="M23.55.3a1.71 1.71 0 0 0-1.72-.13C21.13.45 2 8.64 1.26 8.98c-.14 0-1.38.51-1.25 1.53a1.87 1.87 0 0 0 1.23 1.41l4.85 1.66c.32 1.07 1.51 5 1.77 5.87.17.53.43 1.22.9 1.36a1.1 1.1 0 0 0 1.08-.19l3-2.75 4.79 3.73.12.07a2.33 2.33 0 0 0 .93.22 1.85 1.85 0 0 0 .66-.13 2 2 0 0 0 1-1.06l3.58-18.6a1.68 1.68 0 0 0-.37-1.8ZM10.37 14.19l-1.64 4.37L7.1 13.1l12.55-9.28Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Telegram;
