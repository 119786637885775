const Phone = () => {
  return (
    <svg width="19" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.84 8.31a.73.73 0 0 1-.73-.73c0-.35-.35-1.08-.94-1.72-.58-.62-1.22-.98-1.75-.98a.73.73 0 0 1 0-1.46c.95 0 1.95.51 2.82 1.43.82.87 1.34 1.94 1.34 2.72 0 .41-.33.74-.74.74ZM18.27 8.31a.73.73 0 0 1-.73-.73c0-3.37-2.75-6.1-6.11-6.1A.74.74 0 0 1 11.42 0C15.6-.01 19 3.4 19 7.57c0 .4-.33.73-.73.73ZM8.6 12.3l-1.76 1.76a.95.95 0 0 1-1.34 0l-.31-.3a27 27 0 0 1-2.65-3.1A16.94 16.94 0 0 1 .67 7.42 8.16 8.16 0 0 1 0 4.32c0-.65.11-1.27.34-1.84.23-.58.6-1.11 1.1-1.59C2.03.3 2.7 0 3.4 0c.27 0 .53.06.77.17.25.11.47.29.64.53l2.2 3.11c.17.24.3.46.38.66a1.29 1.29 0 0 1-.07 1.25c-.12.23-.3.46-.53.68l-.72.75a.5.5 0 0 0-.15.38c0 .08 0 .15.03.22l.07.2c.17.3.47.71.89 1.2.42.5.88 1 1.37 1.5l.3.3c.38.36.39.97 0 1.35ZM18.97 15.51a2.41 2.41 0 0 1-.24 1.04 4.28 4.28 0 0 1-2.2 2.09h-.03a4.8 4.8 0 0 1-1.82.36c-.97 0-2-.23-3.1-.7-1.1-.46-2.18-1.09-3.27-1.87-.37-.28-.74-.56-1.09-.85l3.1-3.1c.27.2.51.34.71.45l.17.08c.08.02.15.03.24.03.16 0 .28-.05.39-.16l.72-.71c.24-.24.47-.42.68-.53.22-.14.44-.2.68-.2.18 0 .37.04.58.12.2.09.43.21.66.37l3.15 2.23c.24.18.42.38.52.61.1.24.15.48.15.74Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Phone;
