const Images = () => {
  return (
    <svg width="54" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="1" clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.314 4.173 13.51.05a4.87 4.87 0 0 0-3.869 1.13A5.072 5.072 0 0 0 7.74 4.64l-.656 5.453H5.051c-2.885 0-5.05 2.593-5.05 5.52v27.2c-.072 2.79 2.1 5.113 4.851 5.186.066.002.133.002.2.002h36c2.885 0 5.508-2.262 5.508-5.188v-1.064a6.51 6.51 0 0 0 2.492-1.064 5.544 5.544 0 0 0 1.901-3.591l3.017-27.001c.307-2.933-1.768-5.571-4.656-5.92Zm-5.377 38.639c0 1.463-1.443 2.527-2.886 2.527h-36c-1.303.038-2.39-1.002-2.429-2.323a2.409 2.409 0 0 1 .003-.204V37.89l10.164-7.581a3.115 3.115 0 0 1 4.066.2l7.147 6.383a6.114 6.114 0 0 0 3.87 1.464 5.905 5.905 0 0 0 3.147-.865l12.918-7.581v12.902Zm-14.295-7.58 14.295-8.446V15.614c-.1-1.562-1.344-2.795-2.886-2.86h-36c-1.443 0-2.426 1.396-2.426 2.86v18.953l8.656-6.384a5.773 5.773 0 0 1 7.278.266l7.214 6.45a3.307 3.307 0 0 0 3.869.333Zm21.707-25.5-.001.014-.001.013-3.082 27.001c.005.7-.31 1.363-.853 1.796-.134.136-.353.237-.536.321-.175.08-.316.146-.316.21V15.613c-.104-3.03-2.52-5.451-5.509-5.52H9.707l.59-5.187a3.07 3.07 0 0 1 .984-1.73 2.985 2.985 0 0 1 1.967-.532l35.738 4.19c1.442.139 2.5 1.437 2.363 2.9Zm-24.003 7.21c-3.042 0-5.508 2.5-5.508 5.586 0 3.085 2.466 5.586 5.508 5.586 3.043 0 5.509-2.5 5.509-5.586 0-3.085-2.466-5.587-5.509-5.587Zm0 8.512c-1.593 0-2.885-1.31-2.885-2.926s1.292-2.926 2.885-2.926c1.594 0 2.886 1.31 2.886 2.926s-1.292 2.926-2.886 2.926Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h54v48H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Images;
