const Profile = () => {
  return (
    <svg width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.97 1.33a4 4 0 1 0-5.95 5.33 4 4 0 0 0 5.95-5.33Zm-1.48 3.4h-.74v.78c0 .41-.34.75-.75.75a.76.76 0 0 1-.75-.75v-.78h-.74a.76.76 0 0 1-.75-.75c0-.41.34-.75.75-.75h.74v-.71c0-.41.34-.75.75-.75s.75.34.75.75v.71h.74a.75.75 0 1 1 0 1.5Z"
        fill="#838D98"
      />
      <path
        d="M20 11c0-1.31-.25-2.57-.72-3.72a3.81 3.81 0 0 1-1.37.61 8.43 8.43 0 0 1-1.87 9.08 4.63 4.63 0 0 0-1.1-1c-2.71-1.82-7.15-1.82-9.88 0-.44.29-.8.63-1.1 1A8.48 8.48 0 0 1 13.1 3.09l.1-.35c.12-.37.29-.71.52-1.02A10.02 10.02 0 0 0 0 11c0 2.9 1.25 5.51 3.23 7.34l-.01.02c.1.1.22.18.32.27l.17.14c.18.15.38.29.57.43l.2.14c.19.13.39.25.6.36l.22.13a6.1 6.1 0 0 0 .87.41 8.75 8.75 0 0 0 1.62.51l.22.05c.28.06.56.1.85.13l.12.02a11.6 11.6 0 0 0 2.15-.02c.29-.03.57-.07.85-.13l.22-.05c.24-.06.49-.11.72-.19l.24-.08c.22-.08.45-.15.66-.24l.24-.11a9 9 0 0 0 .63-.3l.22-.13.6-.36.2-.14c.2-.14.39-.28.57-.43l.17-.14.32-.27-.01-.02A9.95 9.95 0 0 0 20 11Z"
        fill="#838D98"
      />
      <path d="M10 5.93a3.75 3.75 0 0 0-.05 7.49h.18A3.74 3.74 0 0 0 10 5.93Z" fill="#838D98" />
    </svg>
  );
};

export default Profile;
