const YouTube = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.5 6.2a3 3 0 0 0-2.1-2.08c-1.86-.5-9.39-.5-9.39-.5s-7.5-.01-9.4.5A3 3 0 0 0 .54 6.2 31.25 31.25 0 0 0 0 12c0 1.94.17 3.88.52 5.78a3 3 0 0 0 2.09 2.1c1.86.5 9.4.5 9.4.5s7.5 0 9.39-.5a3 3 0 0 0 2.09-2.1c.34-1.9.51-3.84.5-5.78.01-1.95-.16-3.89-.5-5.8ZM9.6 15.6V8.4l6.26 3.61-6.26 3.6Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default YouTube;
