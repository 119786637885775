import AdminNavigate from "../../../Components/AdminNavigation/AdminNavigation";

const Home = () => {
  return (
    <main className="main">
      <AdminNavigate />
    </main>
  );
};

export default Home;
